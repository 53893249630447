<template>
  <v-main>
    <v-row gutters="2" justify="end">
      <v-col cols="12" md="12">
        <Buttons
          :btnCancel="false"
          :textSave="textButton"
          v-on:save="addCourse"
        />
      </v-col>
    </v-row>
    <v-row gutters="2">
      <v-col cols="12" md="12">
        <CourseTable></CourseTable>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
// Import internal scripts
// Import General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// Import components
import CourseTable from "@/view/content/components/products_addons/course/Table";
import { mapActions } from "vuex";
import Buttons from "@/view/content/components/Buttons";
export default {
  components: { CourseTable, Buttons },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PRODUCTS_ADDONS.TITLE") },
    ]);
    this.getCourses();
  },
  methods: {
    ...mapActions(["getCourses"]),
    addCourse() {
      this.$router.push({
        name: "product-course-new",
        params: { operation: "new" },
      });
    },
  },
  computed: {
    textButton() {
      return `${this.$i18n.t("PRODUCTS_ADDONS.NEW")}`;
    },
  },
};
</script>

<style scoped>
.btn-outline-primary {
  border-radius: 0;
}
ul {
  padding: 0px !important;
}
</style>
