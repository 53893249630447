<template>
  <v-card>
    <v-card-text>
      <v-row gutters="2" justify="end">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row gutters="2" justify="center">
        <v-col cols="12" md="12">
          <v-data-table :headers="headers" :items="courses" :search="search">
            <template v-slot:[`item.name`]="{ item }">
              <router-link
                :to="{
                  path: `/products/course/edit/${item.productId}`,
                }"
              >
                {{ item.product_basic.name }}
              </router-link>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              {{ item.updated_at | moment(campus.date_format) }}
            </template>
            <template v-slot:[`item.ACTIONS`]="{ item }">
              <v-icon @click="showDialogDelete(item.productId)">delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              deleteRecord();
              isShowDialogDelete = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ForceDelete
      typeProduct="COURSE"
      :validatePromotions="validatePromotions"
      :validatePackages="validatePackages"
      :productId="dataCourse"
      :redirect="false"
      @close-force-delete="cleanDataForceDelete()"
    ></ForceDelete>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";
export default {
  components: {
    ForceDelete,
  },
  data() {
    return {
      isShowDialogDelete: false,
      search: "",
      headers: [
        {
          text: this.$t("PRODUCTS_ADDONS.NAME"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.CATEGORY"),
          align: "left",
          sortable: true,
          value: "category.name",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.LANGUAGE"),
          align: "left",
          sortable: true,
          value: "language[0].name",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.CAMPUS"),
          align: "left",
          sortable: true,
          value: "product.campus.name",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.LAST_UPDATED"),
          align: "left",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$t("PRODUCTS_ADDONS.ACTIONS"),
          align: "center",
          sortable: false,
          value: "ACTIONS",
        },
      ],
      dataCourse: {},
      validatePromotions: [],
      validatePackages: [],
    };
  },
  methods: {
    ...mapActions(["deleteCourse", "getCourses"]),
    showDialogDelete(data) {
      this.dataCourse = data;
      this.isShowDialogDelete = true;
    },
    deleteRecord() {
      this.deleteCourse(this.dataCourse).then(data => {
        if (data.success) {
          this.getCourses();
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        } else {
          this.validatePromotions = data.promotion;
          this.validatePackages = data.package;

          this.isShowDialogForceDelete = true;
          this.keyValidateDelete += 1;
        }
      });
    },
    cleanDataForceDelete() {
      this.validatePromotions = [];
      this.validatePackages = [];
    },
  },
  computed: {
    ...mapGetters(["courses", "campus"]),
  },
};
</script>
